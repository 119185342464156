.sectionLogin{
    display: flex;
    flex-direction: column;
    width: 25vw;
    background: white;
    margin-top: 2vw;
    padding: 1vw 0vw 1vw 3vw;
    border-radius: 7px;
}

.labelInput{
    text-align: left;
    width: 75%;
    margin-bottom: -12px;
    margin-top: 1vw;
    font-size: 0.9vw;
    color: #747474;
}

@media only screen and (max-width: 700px) {
    .labelInput{
        font-size: 4.3vw !important;  
        margin-bottom: 0vw;
        font-weight: 100;
        color: black;
        margin-top: 2vw;
        padding-left: 5vw;
    }
    .makeStyles-sectionContainer-5 .MuiInputBase-root{
        width: 82% !important;
    }
}