swd-pin-field input{
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.3rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 3.5rem;
  width: 3rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;  
}

swd-pin-field{    
  justify-content: center;
}