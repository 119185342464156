* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  transition: all 0.3s;
}

html,
body {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

.Mui-checked {
  color: #f96123 !important;
}

@-webkit-keyframes orbit {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes orbit {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.rtf--mb__c {
  padding: 0 !important;
  margin-right: 0vw !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.swal2-blue-confirm-button {
  background-color: rgb(0, 51, 83) !important;
}

.swal2-styled.swal2-confirm {
  background-color: rgb(249, 97, 35);
}

.inputCreateAccountOperators::placeholder {
  color: #ffffff;
  font-style: italic;
  font-size: 7vw;
}

.hidecss-tj5bde-Svg .css-tj5bde-Svg {
  display: none !important;
}

.MuiButton-containedPrimary {
  background-color: #003350 !important;
  color: white !important;
}

.hidemakeStyles-modalRate-46 .makeStyles-modalRate-17 {
  background: #7f191900 !important;
}

.handInHover:hover {
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
    0px 0px 5.3px rgba(0, 0, 0, 0.028), 0px 0px 10px rgba(0, 0, 0, 0.035),
    0px 0px 17.9px rgba(0, 0, 0, 0.042), 0px 0px 33.4px rgba(0, 0, 0, 0.05),
    0px 0px 80px rgba(0, 0, 0, 0.07);
}

.hideThis {
  display: none;
}

.whitespace {
  height: 3vh;
}

.houseIconContainer {
  background: #062a3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -13px;
  padding: 0 2vw 3px 2vw;
}
.houseIconContainer p {
  color: #fa7d4a;
  font-size: 3vw;
  text-align: center;
}

.houseIconContainer img {
  margin-top: 1vh;
}

.createtransferenceinputrate input {
  border: none;
  border-bottom: none;
}
.createtransferenceinputrate .MuiInput-underline:before {
  border: none;
  border-bottom: none !important;
}
