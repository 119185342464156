@import "../../../assets/styles/variables.scss";

.rates-of-the-day-screen {
  background-color: white;
  height: 93vh;
  padding: 4vh 10vw 0 10vw;

  > h3 {
    color: $blue;
  }

  > .MuiButtonBase-root {
    background-color: $orange !important;
    color: white;
    border-radius: 15px;
    padding: 1vh 0 1vh 0;
    margin-top: 2vh;
    width: 100%;
    text-transform: capitalize;
  }

  .rates-of-the-day-dollars {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
  }
}
