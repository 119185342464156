.summary-new-dashboard {
  background-color: white;
  border-radius: 10px;
  padding: 4vw 4vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 2.8px 0px 4.8px rgba(0, 0, 0, 0.014),
    6.7px 0px 11.5px rgba(0, 0, 0, 0.02), 12.5px 0px 21.7px rgba(0, 0, 0, 0.025),
    22.3px 0px 38.6px rgba(0, 0, 0, 0.03),
    41.8px 0px 72.3px rgba(0, 0, 0, 0.036), 100px 0px 173px rgba(0, 0, 0, 0.05);

  .last {
    background-color: #f9f9fb;
    border-radius: 15px;
    display: flex;
    padding: 1vw 3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > h4 {
      font-weight: lighter;
      font-size: 3vw;
      color: #8a899a;
      margin-bottom: 0.6vh;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        margin-right: 1vw;
      }

      h4 {
        color: #8a899a;
        font-size: 5vw;
      }
    }
  }

  .pending {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      color: #1c1939;
      font-weight: lighter;
      font-size: 4vw;
      margin-bottom: 1vh;
    }

    .pending-number-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .circle {
        background-color: red;
        border-radius: 50%;
        height: 1vh;
        width: 1vh;
        margin-right: 1vw;
      }

      h1 {
        color: red;
        margin-left: 1vw;
        margin-right: 2vw;
        font-size: 7vw;
      }

      h4 {
        color: #1c1939;
        font-weight: lighter;
        margin-bottom: 0;
      }
    }
  }
}

.transaction_list_dashboard {
  > h4 {
    color: #003f62;
    font-weight: lighter;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}

.history_transaction_admin_header_filter {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
