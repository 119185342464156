.section-layout {
  background-color: #f9f9fb;
  height: 100vh;
  position: relative;

  .blue_rectangle {
    background-color: #003f62;
    height: 12vh;
    width: 100vw;
    margin-top: -1%;
  }

  .container-section {
    position: absolute;
    top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    width: 100vw;
  }

  .summary-new-dashboard-title {
    color: white;
    font-weight: lighter;
    margin-bottom: 1vh;
    text-align: right;
  }
}

.header-sections {
  background-color: #003f62;
}

.section_layout_return_header {
  .row {
    i {
      font-size: 3.5vw !important;
      margin-right: 1vw !important;
    }

    span {
      font-size: 3.5vw;
      font-weight: lighter;
    }
  }

  > h5 {
    margin-left: 3.5vw;
    font-size: 5vw;
  }
}
