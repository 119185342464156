 .svgHeader{
    width: 6vw;
    max-width: 40px;
    overflow: visible;
    margin-bottom: 14vw;
    margin-top: 3vw;
    margin-left: -111vw !important;
}

@media only screen and (min-width: 700px) {
    .svgHeader{
        width: 77% !important;
        overflow: visible;
        max-width: 40px;
        margin-top: 3vw;
        margin-bottom: 27vw;
        margin-left: -50vw !important;
    }
}
