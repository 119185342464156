@import "../../../assets/styles/variables.scss";

.containerRates h2 {
  border-top: none;
  margin-top: -1vw;
  padding-left: 5vw;
}

.containerRates h2 span {
  margin-left: 5vw;
}

.containerRates {
  background-color: white;
  width: 100vw;
}

.rateContainer {
  color: white;
  display: flex;
  justify-content: space-between;
  width: 92vw;
  background-color: #022133;
  margin: auto;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 4vw;
}

.rateId {
  background-color: #f96123;
  padding: 2vw 4vw;
  border-radius: 5px;
  font-weight: 800;
}

.rateAmount {
  font-size: 5vw;
  font-weight: 700;
  padding-left: 3vw;
}

.modalRateContainer {
  background: #000000bf;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalRate {
  width: 80vw;
  background: white;
  padding: 8vw;
  position: relative;
}

.modalRate h2 {
  font-size: 7vw;
  margin-bottom: 6vw;
  font-weight: 300;
}

.modalRate h5 {
  font-size: 5vw;
  text-align: center;
  margin-bottom: 9vw;
}
.modalRate h5 span {
  color: #ff5722;
  font-weight: 800;
}
.modalRate .containerButtons {
  display: flex;
  justify-content: space-evenly;
}

.modalRate .containerButtons button {
  border: none;
  border-radius: 5px;
  font-weight: 700;
  padding: 2vw 6vw;
  font-size: 4vw;
}

.modalRate .containerButtons .b1 {
}

.modalRate .containerButtons .b2 {
  background: #02334e;
  color: white;
}
.closeModal {
  position: absolute;
  right: 4%;
  top: 5%;
}

@media only screen and (min-width: 700px) {
  main {
    padding-top: 0;
  }
  .containerRates h2 {
    font-size: 2vw;
  }
  .containerRates h2 span {
    margin-left: 1vw;
  }

  .rateContainer {
    margin-bottom: 1vw;
  }

  .rateAmount {
    font-size: 2vw;
  }

  .rateId {
    padding: 1.2vw;
  }
  .modalRate {
    padding: 3vw 0;
    width: 32vw;
  }
  .modalRate h5 {
    font-size: 2vw;
    margin-bottom: 2vw;
  }
  .modalRate input {
    font-size: 2vw;
  }
  .modalRate .containerButtons button {
    padding: 1vw 2vw !important;
    font-size: 1vw;
  }
}

.InputDollarRow {
  padding: 0vw 4vw 5vh;
  display: flex;
  justify-content: space-between;
  margin-top: -1vh;

  .InputDollar {
    position: relative;
    background: rgb(255, 231, 221);
    padding-top: 2vh;
    width: 42vw;

    input {
      background: rgb(255, 231, 221);
      border: none;
      border-bottom: 2px solid $orange;
      width: 100%;
      height: 6vh;
      text-align: center;
      padding-bottom: 0;
      font-size: 5vw;
    }

    p {
      color: $orange;
      font-size: 2.5vw;
      position: absolute;
      top: 10%;
      left: 5%;
    }
  }
}

.rate-day-container {
  color: black;
  padding: 0 4vw;

  .input {
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin-top: 3vh;
    margin-bottom: 1vh;
    padding-left: 4vw;
    position: relative;

    input {
      background-color: transparent;
      border: none;
      width: 100%;
    }

    button {
      border-radius: 15px;
      background-color: $blue;
      border: none;
      color: white;
      padding: 2vh 5vw;
    }

    p {
      background: #ffeae2;
      color: black;
      position: absolute;
      padding: 1vw 2vw;
      border-radius: 5px;
      font-size: 2.5vw;
      top: -20%;
      left: 5%;
    }
  }

  .suggested-amount {
    width: 100%;
    color: black;
    font-weight: 400;
    font-size: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profit-true {
    color: green;
    font-size: 3vw;
  }
  .profit-false {
    color: red;
    font-size: 3vw;
  }
}

.title-rate-country {
  color: $blue;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.rate-of-day-screen {
  .css-13cymwt-control {
    border: 2px solid $blue;
    border-radius: 15px;
    padding: 1vh 1vw;
  }
}

.dayratescreen .makeStyles-header-1 {
  padding-top: 0 !important;
}

.RateOfDayCountryItemContainer {
  margin-top: -0.5vh;

  .RateOfDayCountryItem {
    border: none;
    background: #062a3f;
    text-align: center;
    padding: 2vh 3vw;
    display: flex;
    height: 7vh;
    font-size: 3vw;
    flex-direction: column;
    align-items: center;
    width: 25vw;

    img {
      width: 53%;
      margin-top: 0vh;
    }
  }

  .RateOfDayCountryItemExpanded {
    position: absolute;

    .RateOfDayCountryItem {
      height: 8vh;

      img {
        width: 53%;
        margin-bottom: 0.5vh;
      }
    }
  }
}
