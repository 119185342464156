.new_transaction {
  background-color: white;
  border-radius: 15px;
  box-shadow: 2.8px 0px 4.8px rgba(0, 0, 0, 0.014),
    6.7px 0px 11.5px rgba(0, 0, 0, 0.02), 12.5px 0px 21.7px rgba(0, 0, 0, 0.025),
    22.3px 0px 38.6px rgba(0, 0, 0, 0.03),
    41.8px 0px 72.3px rgba(0, 0, 0, 0.036), 100px 0px 173px rgba(0, 0, 0, 0.05);
  margin-bottom: 2vh;
  padding: 3vw 4vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .logo {
    padding-left: 2vw;
    padding-right: 2vw;
    width: 18vw;

    img {
      width: 100%;
    }
  }

  .data {
    font-size: 3.5vw;
    padding-left: 4vw;
    width: 100%;

    .top {
      display: flex;
      justify-content: space-between;

      h4 {
        color: #464646;
        font-weight: normal;
      }

      h5 {
        color: #fa7d4a;
        font-weight: bold;
      }
    }

    .medium {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h4 {
        color: #464646;
        font-weight: lighter;

        b {
          font-weight: bold;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #707070;
        text-align: end;
        font-size: 2.5vw;

        h4 {
          font-weight: lighter;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3vw;
    }
  }

  .transaction-status {
    color: white;
    border-radius: 15px;
    margin-top: 1vh;
    font-size: 3.5vw;
    padding: 1vw 4vw;
    text-align: center;
    width: 100%;
  }
}
